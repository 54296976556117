@media print {
    main > * {
        display:none;
      }
    header {    
        display: none;
    }

    #summary-modal {
        display: block;
        position: absolute;
        width: 100%;
    }
    #summary-modal div {
        transform: none;
        max-width: none;
        position: unset;
        top: 0;
        left: 0;
        box-shadow: none;
    }
    #summary-modal div section {
        max-height: min-content;
        overflow: unset;
    }

    button {
        display: none;
    }
}